<template>
  <div class="projects">
    <Header
      :details="{
        title: 'Projects',
        back: { name: 'projects' },
        actions: false,
      }"
    />

    <!--- HERE --->
    <div class="d-flex" style="margin-top: 20px">
      <div class="col-8 left-side">
        <div class="box-container">
          <div class="input-box">
            <input name="name" type="text" :placeholder="$t('properties.title.placeholder')" v-model="project.name" />
          </div>
          <header class="lang d-flex justify-content-end">
            <Select class="lang-select" v-model="currentLanguage" :options="$store.state.global.availableLanguages" />
          </header>
          <template v-for="(lang, index) in $store.state.global.availableLanguages">
            <template v-if="lang.value === currentLanguage">
              <div class="input-box" :key="'descr-' + index">
                <Textarea
                  name="description"
                  textareaClass="editor"
                  is-counter="true"
                  max="400"
                  v-model="translations[index].description"
                />
              </div>
            </template>
          </template>
        </div>
        <div class="flex box-container average">
          <h1>{{ $t("projects.overall_data") }}</h1>
          <div>
            <h2>{{ $t("properties.type.title") }}</h2>
            <p class="types">
              <template v-if="average.types.length > 0">
                <span v-for="t in average.types" :key="'type-' + t">{{ $t("properties.type." + t) }}</span>
              </template>
              <span v-else>-</span>
            </p>
          </div>
          <div>
            <h2>{{ $t("properties.price") }}</h2>
            <p class="lower">
              <strong>{{ average.price.from === null ? "-" : average.price.from | splitNumber }} </strong
              >{{ $store.getters.getCurrency }} {{ $t("overall.to") }}
              <strong>{{ average.price.from === null ? "-" : average.price.to | splitNumber }} </strong
              >{{ $store.getters.getCurrency }}
            </p>
          </div>
          <div>
            <h2>{{ $t("properties.bathrooms") }}</h2>
            <p class="lower">
              {{ average.bathrooms.from === null ? "-" : average.bathrooms.from }} {{ $t("overall.to") }}
              {{ average.bathrooms.from === null ? "-" : average.bathrooms.to }}
            </p>
          </div>
          <div>
            <h2>{{ $t("properties.bedrooms") }}</h2>
            <p class="lower">
              {{ average.bedrooms.from === null ? "-" : average.bedrooms.from }} {{ $t("overall.to") }}
              {{ average.bedrooms.to === null ? "-" : average.bedrooms.to }}
            </p>
          </div>
        </div>
        <div>
          <div class="property-list-container col-12">
            <div class="phases">
              <div class="phases-tabs d-flex">
                <div>
                  <button
                    @dblclick="togglePhaseEdition($event, p, i)"
                    :class="{ active: currentPhase === p }"
                    class="phase-tab"
                    v-for="(p, i) in phases"
                    :key="p.token"
                    @click="currentPhase = p"
                  >
                    <span
                      class="title"
                      contenteditable
                      :class="{ disabled: !p.edit }"
                      @keydown="editPhaseName($event, i, p)"
                      spellcheck="false"
                      ><span v-once>{{ p.name }}</span></span
                    >
                  </button>
                </div>

                <button @click="addPhase" class="add-phase phase-tab">+ {{ $t("projects.add_phase") }}</button>
              </div>
            </div>
            <template v-if="phases.length > 0">
              <div class="general-infos hide">
                <header>
                  <h2>{{ $t("projects.general_infos") }}</h2>
                </header>
                <main class="d-flex">
                  <div>
                    <h2>{{ $t("overall.name") }}</h2>
                    <input type="text" :placeholder="$t('projects.phase_name')" v-model="currentPhase.name" />
                  </div>
                  <div>
                    <h2>{{ $t("overall.end_date") }}</h2>
                    <Datepicker :placeholder="$t('overall.click_to_add_date')" v-model="currentPhase.end_date" />
                  </div>
                </main>
              </div>
              <div class="add-property">
                <Autocomplete
                  :placeholder="$t('projects.add_property')"
                  @save="addProperty"
                  @search="$store.dispatch('properties/search', $event)"
                  :options="$store.getters['properties/options']"
                  :clearOnSave="true"
                />
              </div>
              <div class="table-list">
                <ul class="labels d-flex">
                  <li>
                    <button>{{ $t("overall.reference") }}</button>
                  </li>
                  <li>
                    <button>{{ $t("overall.name") }}</button>
                  </li>
                  <li>
                    <button>{{ $t("properties.type.title") }}</button>
                  </li>
                  <li>
                    <button>{{ $t("properties.bathrooms") }}</button>
                  </li>
                  <li>
                    <button>{{ $t("properties.bedrooms") }}</button>
                  </li>
                  <li>
                    <button>{{ $t("properties.total_area") }}</button>
                  </li>
                  <li>
                    <button>{{ $t("overall.location") }}</button>
                  </li>
                </ul>
                <div class="elements">
                  <template v-if="currentPhase.properties && currentPhase.properties.length > 0">
                    <div class="line" v-for="p in currentPhase.properties" :key="p.token">
                      <router-link class="flex" :to="{ name: 'edit-property', params: { token: p.token } }">
                        <div class="element">{{ p.reference ? p.reference : "-" }}</div>
                        <div class="element">{{ p.translations[0].title ? p.translations[0].title : "-" }}</div>
                        <div class="element">{{ p.type ? $t("properties.type." + p.type) : "-" }}</div>
                        <div class="element">{{ p.bathrooms ? p.bathrooms : "-" }}</div>
                        <div class="element">{{ p.bedrooms ? p.bedrooms : "-" }}</div>
                        <div class="element">{{ p.total_area ? p.total_area : "-" }}</div>
                        <div class="element">{{ p.address.city ? p.address.city : "-" }}</div>
                      </router-link>
                      <button class="delete" @click="removeProperty(p)"><crossSVG /></button>
                    </div>
                  </template>
                  <span class="no-element" v-else>{{ $t("properties.no_property") }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div style="margin-top: 0" class="col-4 right-side">
        <div class="box box-container box-element">
          <div class="box-thumbnail">
            <label for="thumbnail" class="add-thumbnail change-image" v-if="!project.image && !tempImage">
              <span class="d-flex align-items-center justify-content-center"
                ><em><thumbnailSVG />{{ $t("properties.library.add_picture") }}</em></span
              >
            </label>
            <label for="thumbnail" v-else>
              <img :src="tempImage ? tempImage : project.image" alt="Thumbnail" />
            </label>
            <input class="hide" ref="image" type="file" @change="previewFiles" id="thumbnail" />
          </div>
          <div>
            <h2>{{ $t("overall.completion_date") }}</h2>
            <Datepicker
              :fromDate="todayDate.getFullYear() - 10"
              v-model="project.end_date"
              :placeholder="$t('overall.click_to_add_date')"
            />
          </div>
          <div class="builder">
            <h2>{{ $t("overall.builder") }}</h2>
            <SelectSearch :options="builders" v-model="project.builder" />
            <!--<Autocomplete
                    :placeholder="$t('projects.add_property')"
                    @save="addProperty"
                    @search="$store.dispatch('properties/search', $event)"
                    :options="$store.getters['properties/options']"
                    :clearOnSave="true"
            />-->
          </div>
          <div>
            <AddressAutocomplete v-model="address" :placeholder="$t('projects.add_address')" />
          </div>
        </div>
        <div class="other-specs box-container">
          <h2>{{ $t("properties.partner") }}</h2>
          <div>
            <label for="partner_first_name">{{ $t("overall.first_name") }}</label>
            <input id="partner_first_name" type="text" v-model="partner.first_name" />
          </div>
          <div>
            <label for="partner_last_name">{{ $t("overall.last_name") }}</label>
            <input id="partner_last_name" type="text" v-model="partner.last_name" />
          </div>
          <div>
            <label for="partner_company">{{ $t("overall.company") }}</label>
            <input id="partner_company" type="text" v-model="partner.company" />
          </div>
          <div>
            <label for="partner_email">{{ $t("overall.email") }}</label>
            <input id="partner_email" type="text" v-model="partner.email" />
          </div>
          <div>
            <label for="partner_phone">{{ $t("overall.phone") }}</label>
            <input id="partner_phone" type="text" v-model="partner.phone" />
          </div>
        </div>
      </div>
    </div>
    <div class="actions-bar">
      <template v-if="!loading">
        <button class="cancel">{{ $t("overall.cancel") }}</button>
        <button class="cta save fixed-button" @click="save">
          <span class="txt">{{ $t("overall.save") }}</span>
        </button>
      </template>
      <span class="loader" v-else></span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import notifBus from "@/bus/notification-bus";
import modalBus from "@/bus/modal-bus";

export default Vue.extend({
  name: "EditsProject",
  data() {
    return {
      loading: false,
      tempImage: null,
      currentPhase: {
        name: null,
        end_date: null,
        properties: [],
      },
      project: {
        id: null,
        image: null,
        token: null,
        end_date: null,
        builder: null,
      },
      partner: {
        first_name: null,
        last_name: null,
        company: null,
        email: null,
        phone: null,
      },
      average: {
        bedrooms: {
          from: null,
          to: null,
        },
        bathrooms: {
          from: null,
          to: null,
        },
        price: {
          from: null,
          to: null,
        },
        types: [],
      },
      builders: [],
      address: null,
      phases: [],
      translations: [],
      currentLanguage: this.$cookies.get("usr_lang"),
    };
  },
  methods: {
    removeProperty(property) {
      const temp = this.currentPhase.properties.find((el) => el.id === property.id);
      this.currentPhase.delete = this.currentPhase.delete ? this.currentPhase.delete : [];
      this.currentPhase.delete.push({
        phase: this.currentPhase.id,
        property: temp.id,
      });
      this.currentPhase.properties = this.currentPhase.properties.filter((el) => el.id !== property.id);
    },
    addProperty(value) {
      this.currentPhase.properties.push(
        this.$store.state.properties.found.find((el) => {
          if (el.id === value.value) return el;
        }),
      );
    },
    searchProperties(value) {
      this.$axios.get("/api/properties/search?search=" + value).then((res) => {
        this.$store.commit("properties/setProperty", res.data);
      });
    },
    deleteProject() {
      this.$axios.delete("api/projects/" + this.project.token).then((res) => {
        notifBus.$emit("show", {
          status: "success",
          message: this.$t("projects.notifications.deleted"),
        });
        this.$router.push({ name: "projects" });
      });
    },
    deletePhase(p) {
      this.phases = this.phases.filter((el) => el !== p);
      this.currentPhase = this.phases[this.phases.length - 1];
    },
    addPhase() {
      this.currentPhase = {
        name: this.$t("overall.phase") + " " + (this.phases.length + 1),
        start_date: null,
        end_date: null,
        properties: [],
      };
      this.phases.push(this.currentPhase);
    },
    save() {
      this.loading = true;
      const formData = new FormData();
      if (this.project.image && this.project.image.length > 400) {
        this.project.image = null;
      }
      if (this.$refs.image.files[0]) formData.append("image", this.$refs.image.files[0]);
      formData.set("project", JSON.stringify(this.project));
      formData.set("translations", JSON.stringify(this.translations));
      formData.set("phases", JSON.stringify(this.phases));
      formData.set("address", JSON.stringify(this.address));
      formData.set("partner", JSON.stringify(this.partner));
      this.$axios
        .post("/api/projects", formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then((res) => {
          notifBus.$emit("show", {
            status: "success",
            message: this.$t("projects.notifications.updated"),
          });
          this.$router.push({ name: "projects" });
        });
    },
    editPhaseName(e, i, p) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.target.blur();
        p.edit = false;
        this.$set(this.phases, i, p);
      } else {
        this.currentPhase.name = e.target.innerText;
      }
    },
    togglePhaseEdition(e, p, i) {
      if (!p.edit) {
        p.edit = true;
        this.$set(this.phases, i, p);
        e.target.firstChild.focus();
      }
    },
    previewFiles(event) {
      let reader = new FileReader();
      let _this = this;
      reader.onload = function() {
        _this.tempImage = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    setFromAndTo(project, property, element) {
      if (property[element] !== null) {
        property[element] = parseInt(property[element]);
        let temp = project[element];
        temp.from = temp.from !== null && temp.from < property[element] ? temp.from : property[element];
        temp.to = temp.to === null || temp.to < property[element] ? property[element] : temp.to;
      }
    },
  },

  watch: {
    phases: {
      deep: true,
      handler() {
        this.phases.forEach((phase) => {
          phase.properties.forEach((property) => {
            if (property.type && !this.average.types.includes(property.type)) {
              this.average.types.push(property.type);
            }

            this.setFromAndTo(this.average, property, "bedrooms");
            this.setFromAndTo(this.average, property, "bathrooms");
            this.setFromAndTo(this.average, property, "price");
          });
        });
      },
    },
    "$store.state.global": {
      immediate: true,
      deep: true,
      handler(el) {
        this.translations = this.$store.state.global.availableLanguages.map((el) => {
          return {
            lang: el.value,
            title: "",
            description: "",
            slug: "",
          };
        });
      },
    },
  },

  created() {
    modalBus.$on("add-phase", (deal) => {
      if (this.phases.findIndex((e) => e.token === deal.token) === -1) this.phases.push(deal);
    });

    if (this.$route.params.token) {
      this.$axios.get("/api/projects/" + this.$route.params.token).then((res) => {
        let project = res.data;
        this.address = project.address;
        this.partner = { ...project.project.partner };
        this.builders = res.data.builders.map((el) => {
          return {
            name: el.name,
            value: el.id,
          };
        });
        this.project = { ...res.data.project };

        this.translations = this._global.availableLanguages.map((l) => {
          const index = project.translations.findIndex((t) => t.lang === l.value);
          if (index !== -1) {
            const temp = { ...project.translations[index] };
            project.translations.splice(index, 1);
            return temp;
          }

          return {
            lang: l.value,
            title: "",
            description: "",
            slug: "",
          };
        });

        res.data.phases.forEach((phase) => {
          this.phases.push(phase);
          phase.properties.forEach((property) => {
            if (property.type && !this.average.types.includes(property.type)) {
              this.average.types.push(property.type);
            }
            this.setFromAndTo(this.average, property, "bedrooms");
            this.setFromAndTo(this.average, property, "bathrooms");
            this.setFromAndTo(this.average, property, "price");
          });
        });

        this.currentPhase =
          this.phases.length > 0
            ? this.phases[0]
            : {
                name: null,
                start_date: null,
                end_date: null,
                properties: [],
              };
      });
    }
  },
});
</script>
<style></style>
